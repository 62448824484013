import type { CustomerRequestAPIPromiseClient } from '@winestyle/api-client/src/ts/api/customer/v1/customer_request_api_grpc_web_pb'
import {
  AddCallRequestRequest,
  AddLandlordRequestRequest,
  AddCorporateClientRequestRequest,
  AddFranchiseRequestRequest,
  AddWholesaleClientRequestRequest, AddSupplierRequestRequest
} from '@winestyle/api-client/src/ts/api/customer/v1/customer_request_api_pb'

type customerSupportRequestReturnType = {
  error: {
    retry?: number
    phone?: string
    email?: string
    name?: string
    comment?: string
    address?: string
    inn?: string
    city?: string
    captcha?: boolean
    representative?: number
    link?: string
    url?: string
  }
}

export type CustomerSupportRequestAPI = {
  addCallRequest: (name: string, phone: string, time_from: string, time_to: string, captcha: string) => Promise<customerSupportRequestReturnType>
  addLandlordRequest: (fio: string, phone: string, email: string, address: string, comment: string) => Promise<customerSupportRequestReturnType>
  addCorporateClientRequest: (inn: string, phone: string, email: string, comment: string, name: string) => Promise<customerSupportRequestReturnType>
  addFranchiseRequest: (name: string, phone: string, email: string, comment: string) => Promise<customerSupportRequestReturnType>
  addWholesaleClientRequest: (name: string, phone: string, city: string, email: string, comment: string, hasLicense: boolean, url: string) => Promise<customerSupportRequestReturnType>
  addSupplierRequest: (
    partnerType: string,
    productTypesList: Array<string>,
    warehousesList: Array<string>,
    deliveryTime: string,
    orderPrice: string,
    paymentList: Array<string>,
    packageTypeList: Array<string>,
    pickup: boolean,
    organizationName: string,
    phone: string,
    email: string,
    link: string,
    comment: string,
  ) => Promise<customerSupportRequestReturnType>
}

export const customerSupportRequestApi = (supportCustomerAPIClient: CustomerRequestAPIPromiseClient): CustomerSupportRequestAPI => {
  const addCallRequest = async (name: string, phone: string, time_from: string, time_to: string, captcha: string): Promise<customerSupportRequestReturnType> => {
    const request = new AddCallRequestRequest()
    request.setName(name)
    request.setPhone(phone)
    request.setTimeFrom(time_from)
    request.setTimeTo(time_to)
    request.setCaptchaCode(captcha)

    const response = await supportCustomerAPIClient.addCallRequest(request)

    let error: customerSupportRequestReturnType['error'] = {}
    const validationErrors = response
      .getValidationError()?.toObject().fieldsMap
      .map(([k, v]) => ([k, v.errorsList[0]?.code]))
    const retry = response.getTooManyRequestsError()?.getRetryAfterSec()
    const invalidCaptcha = response.getInvalidCaptchaError()?.toObject()

    if (validationErrors?.length) {
      error = Object.fromEntries(validationErrors)
    }

    if (retry) {
      error.retry = retry
    }

    if (invalidCaptcha) {
      error.captcha = true
    }

    return { error }
  }

  const addLandlordRequest = async (fio: string, phone: string, email: string, address: string, comment: string): Promise<customerSupportRequestReturnType> => {
    const request = new AddLandlordRequestRequest()
    request.setName(fio)
    request.setPhone(phone)
    request.setEmail(email)
    request.setAddress(address)

    if (comment) {
      request.setComment(comment)
    }

    const response = await supportCustomerAPIClient.addLandlordRequest(request)

    let error: customerSupportRequestReturnType['error'] = {}
    const validationErrors = response
      .getValidationError()?.toObject().fieldsMap
      .map(([k, v]) => ([k, v.errorsList[0]?.code]))

    if (validationErrors?.length) {
      error = Object.fromEntries(validationErrors)
    }

    return { error }
  }
  const addCorporateClientRequest = async (inn: string, phone: string, email: string, comment: string, name: string): Promise<customerSupportRequestReturnType> => {
    const request = new AddCorporateClientRequestRequest()
    request.setName(name)
    request.setPhone(phone)
    request.setEmail(email)

    if (comment) {
      request.setComment(comment)
    }

    request.setOrganization(inn)
    request.setHasLicense(false)

    const response = await supportCustomerAPIClient.addCorporateClientRequest(request)

    let error: customerSupportRequestReturnType['error'] = {}
    const validationErrors = response
      .getValidationError()?.toObject().fieldsMap
      .map(([k, v]) => ([k, v.errorsList[0]?.code]))

    if (validationErrors?.length) {
      error = Object.fromEntries(validationErrors)
    }

    return { error }
  }

  const addFranchiseRequest = async (name: string, phone: string, email: string, comment: string): Promise<customerSupportRequestReturnType> => {
    const request = new AddFranchiseRequestRequest()
    request.setName(name)
    request.setPhone(phone)
    request.setEmail(email)

    if (comment) {
      request.setComment(comment)
    }

    const response = await supportCustomerAPIClient.addFranchiseRequest(request)

    let error: customerSupportRequestReturnType['error'] = {}
    const validationErrors = response
      .getValidationError()?.toObject().fieldsMap
      .map(([k, v]) => ([k, v.errorsList[0]?.code]))

    if (validationErrors?.length) {
      error = Object.fromEntries(validationErrors)
    }

    return { error }
  }

  const addWholesaleClientRequest = async (name: string, phone: string, city: string, email: string, comment: string, hasLicense: boolean, url: string): Promise<customerSupportRequestReturnType> => {
    const request = new AddWholesaleClientRequestRequest()
    request.setName(name)
    request.setPhone(phone)
    request.setEmail(email)
    request.setFromUrl(url)

    if (comment) {
      request.setComment(comment)
    }
    request.setHasLicense(hasLicense)

    const response = await supportCustomerAPIClient.addFranchiseRequest(request)

    let error: customerSupportRequestReturnType['error'] = {}
    const validationErrors = response
      .getValidationError()?.toObject().fieldsMap
      .map(([k, v]) => ([k, v.errorsList[0]?.code]))

    if (validationErrors?.length) {
      error = Object.fromEntries(validationErrors)
    }

    return { error }
  }

  const addSupplierRequest = async (
    partnerType: string,
    productTypesList: Array<string>,
    warehousesList: Array<string>,
    deliveryTime: string,
    orderPrice: string,
    paymentList: Array<string>,
    packageTypeList: Array<string>,
    pickup: boolean,
    organizationName: string,
    phone: string,
    email: string,
    link: string,
    comment: string
  ): Promise<customerSupportRequestReturnType> => {
    const request = new AddSupplierRequestRequest()
    request.setPartnerType(partnerType)
    request.setProductTypesList(productTypesList)
    request.setWarehousesList(warehousesList)
    request.setDeliveryTime(deliveryTime)
    request.setOrderPrice(orderPrice)
    request.setPaymentList(paymentList)
    request.setPackageTypeList(packageTypeList)
    request.setPickup(pickup)
    request.setOrganizationName(organizationName)
    request.setPhone(phone)
    request.setEmail(email)
    request.setLink(link)

    if (comment) {
      request.setComment(comment)
    }

    const response = await supportCustomerAPIClient.addSupplierRequest(request)

    let error: customerSupportRequestReturnType['error'] = {}
    const validationErrors = response
      .getValidationError()?.toObject().fieldsMap
      .map(([k, v]) => ([k, v.errorsList[0]?.code]))

    if (validationErrors?.length) {
      error = Object.fromEntries(validationErrors)
    }

    return { error }
  }

  return { addCallRequest, addLandlordRequest, addCorporateClientRequest, addFranchiseRequest, addWholesaleClientRequest, addSupplierRequest }
}
